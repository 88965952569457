<template>
  <div class="mediaitems">
    <v-container v-if="showUploadBox" no-gutters fluid>
      <div
        @dragover="dragoverUploadBox"
        @dragleave="dragleaveUploadBox"
        @drop="dropUploadBox"
        class="mediaitems__uploadbox"
        :class="dragHoveredUploadBox ? 'theme--light accent mediaitems__uploadbox--draghovered' : ''"
      >
        <v-btn fab small class="mediaitems__uploadbox_close" @click="closeUploadBox">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <p class="text-center mediaitems__uploadbox-text title">
          {{ translations.labels.medialib_uploadFileDropDescription }}
          <br/>
          <span class="text-center subtitle-1">
            {{ translations.labels.medialib_otherwiseDivider }}
          </span>
        </p>
        <v-btn @click="triggerInputFile">
          {{ translations.labels.medialib_selectFile }}
        </v-btn>
        <div class="mediaitems__hidden">
          <v-file-input
            ref="uploadFileElement"
            v-model="selectedFile"
            type="file"
            @change="onFileSelected"
          />
        </div>
      </div>
    </v-container>
    <MediaGrid
      v-if="layout == 'grid'"
      :selected="selected"
      :items="items"
      :multiple="multiple"
      :propagate-dbl-click-file="propagateDblClickFile"
      :is-dialog="isDialog"
      @mediagrid:getpublicurl="getPublicUrl"
      @mediagrid:openfolder="openFolder"
      @mediagrid:editfile="editFile"
      @mediagrid:rename="rename"
      @mediagrid:download="download"
      @mediagrid:move="move"
      @mediagrid:delete="deleteItem"
      @mediagrid:restore-file="restoreItem"
      @mediagrid:compress-file="compressItem"
      @mediagrid:update-selected="updateSelected"
      @mediagrid:dblclickfile="dblclickfile"
    />
    <MediaList
      v-else
      :selected="selected"
      :items="items"
      :multiple="multiple"
      :propagate-dbl-click="propagateDblClickFile"
      @medialist:getpublicurl="getPublicUrl"
      @medialist:openfolder="openFolder"
      @medialist:editfile="editFile"
      @medialist:rename="rename"
      @medialist:download="download"
      @medialist:move="move"
      @medialist:delete="deleteItem"
      @medialist:restore-file="restoreItem"
      @medialist:compress-file="compressItem"
      @medialist:update-selected="updateSelected"
      @medialist:dblclickfile="dblclickfile"
    />
  </div>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import Translations from "@/mixins/translations";
import MediaGrid from "@/components/medialib/items/grid/MediaGrid";
import MediaList from "@/components/medialib/items/list/MediaList";

@Component({
  name: "MediaItems",
  components: {
    MediaGrid,
    MediaList
  },
  props: {
    isDialog: Boolean,
    selected: Array,
    layout: String,
    items: Array,
    multiple: {
      type: Boolean,
      default: false
    },
    showUploadBox: {
      type: Boolean,
      default: false
    },
    propagateDblClickFile: {
      type: Boolean,
      default: false
    },
  }
})
export default class MediaItems extends mixins(Translations) {
  selectedFile = null;
  dragHoveredUploadBox = false;

  dragoverUploadBox (event) {
    event.preventDefault();
    this.dragHoveredUploadBox = true;
  }

  dragleaveUploadBox (event) {
    event.preventDefault();
    this.dragHoveredUploadBox = false;
  }

  dropUploadBox (event) {
    event.preventDefault();
    const targetFiles = (event.dataTransfer?.files ?? []);
    this.dragHoveredUploadBox = false;
    if (targetFiles.length == 0) {
      return;
    }
    this.$emit("mediaitems:multi-uploadfile", targetFiles);
  }

  triggerInputFile () {
    const input = this.$refs.uploadFileElement.$el.querySelector("input[type=\"file\"]");
    if (input) {
      input.click();
    }
  }

  onFileSelected () {
    this.$emit("mediaitems:uploadfile", this.selectedFile);
  }

  getPublicUrl (item) {
    this.$emit("mediaitems:getpublicurl", item);
  }

  openFolder (item) {
    this.$emit("mediaitems:openfolder", item);
  }

  editFile (item) {
    this.$emit("mediaitems:editfile", item);
  }

  rename (item) {
    this.$emit("mediaitems:rename", item);
  }

  download (item) {
    this.$emit("mediaitems:download", item);
  }

  deleteItem (item) {
    this.$emit("mediaitems:delete", item);
  }

  restoreItem (item) {
    this.$emit("mediaitems:restore-file", item);
  }

  compressItem (item) {
    this.$emit("mediaitems:compress-file", item);
  }

  move (destination, item) {
    this.$emit("mediaitems:move", destination, item);
  }

  updateSelected (selected) {
    this.$emit("mediaitems:update-selected", selected);
  }

  dblclickfile (file) {
    this.$emit("mediaitems:dblclickfile", file);
  }

  closeUploadBox () {
    this.$emit("mediaitems:closeuploadbox");
  }
}
</script>

<style lang="scss" scoped>
.mediaitems {
  &__hidden {
    display: none;
  }
  &__uploadbox {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 175px;
    border: 5px dashed rgba(0, 0, 0, 0.15);

    &--draghovered {
      border: 5px dashed rgba(0, 0, 0, 0.15)!important;
    }
  }
  &__uploadbox_close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &__uploadbox-text {
    padding-top: 35px;
    padding-bottom: 10px;
  }
}
</style>