  <template>
  <v-sheet class="medialist m-0">
    <v-data-table
      v-model="internalSelected"
      :headers="headers"
      :items="items"
      show-select
      :single-select="!multiple"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item="{ item, isSelected }">
        <tr
          @drag-start="onDragStart"
          @drag-enter="onDragEnter(item)"
          @drag-leave="onDragLeave(item)"
          v-draggable="{ ...item }"
          v-droppable
          @drag-drop="(moveItem(item))($event)"
          @dblclick.stop="doubleClickItem(item, $event)"
          class="medialist__item"
          :class="rowClasses(item, isSelected)"
        >
          <!-- begin row -->

          <!-- preview -->
          <td class="text-start" @contextmenu="onRowContextMenu">
            <v-icon
              :dark="isSelected"
              :disabled="!isSelectable(item)"
              @click="toggleSelected(item, isSelected)"
            >
              {{ isSelected ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
            </v-icon>
          </td>
          <td class="text-start" @contextmenu="onRowContextMenu">
            <v-icon
              v-if="notAnImage(item)"
              :dark="isSelected"
              :size="64"
              class="medialist__icon">
              {{ typeIcon(item) }}
            </v-icon>
            <v-img
              v-else
              class="medialist__img"
              :src="imgThumbSrc(item)"
              height="64"
              width="64"
            ></v-img>
          </td>
          <!-- name -->
          <td class="text-start" @contextmenu="onRowContextMenu">{{ item.name }}</td>
          <!-- refDate -->
          <td class="text-start" @contextmenu="onRowContextMenu">{{ formatDateTime(item.refDate) }}</td>
          <!-- actions -->
          <td class="text-end" @contextmenu="onRowContextMenu">
            <MediaContextMenu
              :ctx-item="item"
              :context-menu="itemContextMenu(item)"
              :with-activator="true"
              @mediacontextmenu:getpublicurl="getPublicUrl"
              @mediacontextmenu:openfolder="openFolder"
              @mediacontextmenu:editfile="editFile"
              @mediacontextmenu:rename="rename"
              @mediacontextmenu:download="download"
              @mediacontextmenu:delete="deleteItem"
              @mediacontextmenu:restore-file="restoreFile"
              @mediacontextmenu:compress-file="compressFile"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :dark="isSelected" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
            </MediaContextMenu>
          </td>

          <!-- /end row -->

        </tr>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import Translations from "@/mixins/translations";
import MediaContextMenu from "@/components/medialib/contextmenu/MediaContextMenu";
import MediaBase from "@/mixins/MediaBase";
import { draggable, droppable } from "v-drag-drop";
import { Utility } from "@/lib/Utility";

@Component({
  name: "MediaList",
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    layout: String,
    items: Array,
    multiple: Boolean,
    propagateDblClickFile: {
      type: Boolean,
      default: false
    },
  },
  components: {
    MediaContextMenu,
  },
  directives: {
    draggable,
    droppable
  },
  watch: {
    internalSelected: function(newVal) {
      if (Utility.clone(newVal) !== Utility.clone(this.selected)) {
        this.$emit("medialist:update-selected", newVal);
      }
    },
    selected: function(newVal) {
      if (Utility.clone(newVal) !== Utility.clone(this.internalSelected)) {
        this.internalSelected = this.selected;
      }
    }
  },
})
export default class MediaList extends mixins(MediaBase, Translations) {
  internalSelected = [];
  dragHovered = null;

  contextMenus = {};

  onRowContextMenu ($event) {
    $event.preventDefault();
  }

  formatDateTime (date) {
    if (!date) {
      return "";
    }
    return Utility.formatDateTime(date);
  }

  itemContextMenu (item) {
    if (typeof this.contextMenus[item.id] === "undefined") {
      this.contextMenus[item.id] = { show: false };
    }
    return this.contextMenus[item.id];
  }

  doubleClickItem (item) {
    if (item.isDir) {
      this.$emit("medialist:openfolder", item);
    } else if (!this.propagateDblClickFile) {
      this.$emit("medialist:editfile", item);
    } else {
      this.$emit("medialist:dblclickfile", item);
    }
  }

  moveItem (item) {
    return (data) => {
      if (!item.isDir) {
        return;
      }
      this.$emit("medialist:move", item, data);
    };
  }

  isSelectable (item) {
    return !!(item?.isSelectable ?? true);
  }

  itemName (item) {
    return item.isDir ? item.name : item.fileName;
  }

  toggleSelected (item, isSelected) {
    if (isSelected) {
      const index = this.internalSelected.findIndex(i => i.id == item.id);
      if (index >= 0) {
        this.internalSelected.splice(index, 1);
      }
    } else {
      if (this.multiple) {
        this.internalSelected.push(item);
      } else {
        this.internalSelected = [ item ];
      }
    }
  }

  getPublicUrl (item, voice) {
    this.$emit("medialist:getpublicurl", item, voice);
  }
  openFolder (item, voice) {
    this.$emit("medialist:openfolder", item, voice);
  }
  editFile (item, voice) {
    this.$emit("medialist:editfile", item, voice);
  }
  rename (item, voice) {
    this.$emit("medialist:rename", item, voice);
  }
  download (item, voice) {
    this.$emit("medialist:download", item, voice);
  }
  deleteItem (item, voice) {
    this.$emit("medialist:delete", item, voice);
  }
  restoreFile (item, voice) {
    this.$emit("medialist:restore-file", item, voice);
  }
  compressFile (item, voice) {
    this.$emit("medialist:compress-file", item, voice);
  }

  onDragStart(item, event) {
    // the parent dir is not draggable; we preventDefault the event if we are trying to drag that
    if (item.isDir && item.isParentDir) {
      event.preventDefault();
      return;
    }
    // system dir are not draggable too
    if (item.isSystem) {
      event.preventDefault();
      return;
    }
  }

  onDragEnter (item) {
    if (!item.isDir) {
      return;
    }
    this.dragHovered = item.id;
  }

  onDragLeave (item) {
    if (this.dragHovered === item.id) {
      this.dragHovered = null;
    }
  }

  themeClasses (item, isSelected) {
    return {
      "theme--dark": (item.id !== this.dragHovered && isSelected),
      "theme--light": !(item.id !== this.dragHovered && isSelected),
    };
  }

  rowClasses (item, isSelected) {
    return {
      "accent": (item.id === this.dragHovered),
      "primary": (item.id !== this.dragHovered && isSelected),
      ...this.themeClasses(item, isSelected),
    };
  }

  get headers () {
    return [
      {
        text: this.translations.labels.medialib_listItemColumn,
        value: "preview",
        sortable: false,
        width: "5%",
      },
      {
        text: this.translations.labels.medialib_listItemName,
        value: "name",
        width: "60%",
      },
      {
        text: this.translations.labels.medialib_listItemDate,
        value: "refDate",
        width: "30%",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        width: "5%",
      },
    ];
  }

  created() {
    this.internalSelected = this.selected;
  }
}
</script>

<style lang="scss" scoped>
.medialist {
  &__selectable {
    cursor: pointer;
  }
  &__item {
    &.theme--dark {
      color: #FFF;
    }
  }
}
</style>