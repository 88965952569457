<script>
import Vue from "vue";
import Component from "vue-class-component";
import { MediaUtility } from "@/lib/MediaUtility";

@Component({
  props: {
    item: Object,
  }
})
export default class MediaBase extends Vue {
  notAnImage (item) {
    return MediaUtility.notAnImage(item);
  }

  typeIcon (item) {
    return MediaUtility.typeIcon(item);
  }

  imgThumbSrc(item) {
    return MediaUtility.imgThumbSrc(item);
  }

  imgSrc (item) {
    return MediaUtility.imgSrc(item);
  }
}
</script>