<template>
  <div>
    <v-dialog
      v-if="item"
      content-class="mediaedit"
      :value="open"
      hide-overlay
      max-width="30%"
    >
      <v-sheet>
        <div class="mediaedit__contents">
          <v-icon v-if="notAnImage(item)" :max-height="256" contain class="mediaedit__icon">{{ typeIcon(item) }}</v-icon>
          <v-img v-else :max-height="256" contain class="mediaedit__img" :src="imgSrc(item)"></v-img>

          <v-divider />

          <div class="mediaedit__metadata">
            <v-text-field
              v-for="(metaField, index) in metaFields"
              :key="index"
              :value="metaFieldValue(metaField)"
              :label="metaFieldLabel(metaField)"
              readonly
            ></v-text-field>
          </div>

          <v-divider />

          <v-form>
            <v-text-field
              v-model="name"
              label="Filename"
            ></v-text-field>

            <v-text-field
              v-model="title"
              label="Title"
            ></v-text-field>

          </v-form>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import MediaBase from "@/mixins/MediaBase";
import MediaCard from "@/components/medialib/items/grid/MediaCard";

@Component({
  name: "MediaEdit",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    item: Object,
  },
  components: {
    MediaCard,
  },
})
export default class MediaEdit extends mixins(MediaBase) {
  title = null;
  name = null;

  close () {
    this.$emit("mediaedit:close");
  }

  metaFieldValue (metaField) {
    return (typeof this.item[metaField] !== "undefined")
      ? this.item[metaField]
      : "- - -";
  }

  metaFieldLabel (metaField) {
    return metaField;
  }

  get mediaType () {
    return "image";
  }

  get metaFields () {
    const commonMetaFields = [ "size", "mimeType" ];
    switch (this.mediaType) {
      case "image":
        return [
          ...commonMetaFields,
          "resolution"
        ];
      case "video":
      case "audio":
        return [
          ...commonMetaFields,
          "duration"
        ];
      case "doc":
      case "docx":
      case "pdf":
        return [
          ...commonMetaFields,
          "pages"
        ];
      default:
        return commonMetaFields;
    }
  }
}
</script>

<style lang="scss">
.mediaedit {
  &__icon {
    font-size: 256px!important;
    margin: 0;
  }
}
</style>