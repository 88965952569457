<template>
  <v-card
    :title="itemTitle(item)"
    class="mediacard"
    :dark="active"
    :elevation="active ? 6 : 2"
    :color="cardColor"
    @dblclick.stop="openCard(item, $event)"
    @click="selectCard(item, $event)"
    @contextmenu="openContextMenu(item, $event)"
  >
    <v-responsive aspect-ratio="1">
      <v-icon v-if="notAnImage(item)" class="mediacard__icon">{{ typeIcon(item) }}</v-icon>
      <v-img v-else aspect-ratio="1" class="mediacard__img" :class="active ? 'mediacard__img--selected' : ''" :src="imgThumbSrc(item)"></v-img>
      <v-card-title class="mediacard__title pa-2 m-t-2 text-truncate v-sheet" :class="titleStyle">
        {{ item.name }}
      </v-card-title>
    </v-responsive>
  </v-card>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import MediaBase from "@/mixins/MediaBase";

@Component({
  name: "MediaCard",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    dragHovered: {
      type: Boolean,
      default: false
    },
    item: Object,
  }
})
export default class MediaCard extends mixins(MediaBase) {
  DBLCLICK_TIME_DELAY = 200;
  clickTime = null;

  openCard (item, $event) {
    clearTimeout(this.clickTime);

    this.$emit("card:open", item, $event);
    if (!this.active) {
      this.emitSelect(item, $event);
    }
  }

  selectCard (item, $event) {
    clearTimeout(this.clickTime);

    this.clickTime = setTimeout(() => {
      this.emitSelect(item, $event);
    }, this.DBLCLICK_TIME_DELAY);
  }

  openContextMenu (item, $event) {
    $event.preventDefault();
    if (!this.active) {
      this.emitSelect(item, $event);
    }
    this.$emit("card:contextmenu", item, $event);
  }

  emitSelect (item, $event) {
    const selectable = !!(item?.isSelectable ?? true);
    if (selectable) {
      this.$emit("card:select", item, $event);
    }
  }

  itemTitle (item) {
    if (item.isDir) {
      return item.name;
    }
    return `${item.path}/${item.name}`.replace(/^[/]{1,2}/, "");
  }

  get titleStyle () {
    return {
      "theme--light": !this.active,
      "theme--dark": !!this.active,
      "primary": !!this.active,
    };
  }

  get cardColor () {
    if (this.dragHovered) {
      return "accent";
    }
    return this.active ? "primary" : "";
  }
}
</script>

<style lang="scss" scoped>
.mediacard {
  position: relative;

  &__title {
    pointer-events: none;
    font-size: 1.3em;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: left;
  }
  &__icon {
    pointer-events: none;
    font-size: 100px;
    margin-top: 10px;
  }
  &__img {
    pointer-events: none;
    &--selected {
      opacity: 0.7;
    }
  }
  @media (max-height: 900px) {
      &__icon {
        font-size: 80px;
      }
  }
}
</style>