import _env from "@/plugins/env";

export class MediaUtility {
  static TYPE_ICONS() {
    return {
      // type icons
      "dir": "mdi-folder-outline",

      "image": "mdi-file-image",
      "video": "mdi-file-video",
      "audio": "mdi-file-music",
      "application": "mdi-file-document",
      "font": "mdi-format-font",
      "text": "mdi-file-document",

      // ext icons
      "doc": "mdi-file-word", "docx": "mdi-file-word", "odt": "mdi-file-word", "rtf": "mdi-file-word",
      "xls": "mdi-file-excel", "xlsx": "mdi-file-excel", "xlsm": "mdi-file-excel", "ods": "mdi-file-excel",
      "ppt": "mdi-file-powerpoint", "pptx": "mdi-file-powerpoint", "pps": "mdi-file-powerpoint", "odp": "mdi-file-powerpoint",
      "pdf": "mdi-file-pdf-box",
      "dwg": "mdi-file-cad", "cad": "mdi-file-cad",
      "zip": "mdi-folder-zip", "rar": "mdi-folder-zip", "tar": "mdi-folder-zip", "7z": "mdi-folder-zip", "gz": "mdi-folder-zip",
      "p7m": "mdi-file-certificate",
      // eslint-disable-next-line no-dupe-keys
      "html": "mdi-file-code", "xml": "mdi-file-code", "xls": "mdi-file-code",
      "db": "mdi-database", "mdb": "mdi-database", "sql": "mdi-database",
    };
  }

  static isImage (item) {
    if (this.isDir(item)) {
      return false;
    }
    return (item?.mimeType ?? "").includes("image/");
  }

  static notAnImage (item) {
    return !this.isImage(item);
  }

  static mainType (item) {
    return (item?.mimeType ?? "invalid/type").split("/")[0];
  }

  static extension (item) {
    const pieces = (item?.name ?? "").toLowerCase().split(".");
    return pieces[pieces.length - 1];
  }

  static isDir(item) {
    return !!item?.isDir;
  }

  static getFileUrl (path) {
    if (!path) {
      return "";
    }
    if (path.startsWith("http")) {
      return path;
    }
    if (path !== "/Links/") {
      return _env("VUE_APP_API_BASE_URL") + "/Files/" + path;
    }
    return path;
  }

  static imgSrc(item) {
    const url = item?.publicUrl ?? item?.url ?? "";
    if (url === "") {
      return "";
    }
    return this.getFileUrl(
      url.replace("\\", "/")
    );
  }

  static imgThumbSrc(item) {
    let src = this.imgSrc(item);
    let pieces = src.split(".");
    const extension = pieces.pop();
    pieces.push("thumb." + extension);
    return pieces.join(".");
  }

  static getBaseName (fullPathName) {
    if (typeof fullPathName === "undefined") {
      return "";
    }
    if (fullPathName === null) {
      return "";
    }
    const lastIndexOfSlash = fullPathName.lastIndexOf("/");
    const lastIndexOfBackslash = fullPathName.lastIndexOf("\\");
    const lastIndexOfTarget = Math.max(lastIndexOfSlash, lastIndexOfBackslash);
    return fullPathName.substring(lastIndexOfTarget + 1);
  }

  static typeIcon(item) {
    if (this.isDir(item)) {
      return item.isSystem
        ? "mdi-folder-cog-outline"
        : "mdi-folder-outline";
    }
    const extension = this.extension(item);
    const mainType = this.mainType(item);
    return this.TYPE_ICONS()[extension]
      ?? this.TYPE_ICONS()[mainType]
      ?? "mdi-file-document-outline";
  }

  static fileMapping(f, isSelectable, path) {
    const updateDate = new Date(f.updateDate);
    const yearMonth =
      updateDate.getFullYear() + "/" + (updateDate.getMonth() + 1);
    return {
      isDir : false,
      isParentDir : false,
      isSelectable : isSelectable,
      path : path,
      name : f.fileName ? f.fileName : "",
      refDate : updateDate,
      yearMonth : yearMonth,
      ...f,
    };
  }
}
