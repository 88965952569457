<template>
  <v-menu
    v-model="contextMenu.show"
    :position-x="withActivator ? null : contextMenu.x"
    :position-y="withActivator ? null : contextMenu.y"
    :absolute="!withActivator"
    offset-y
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <slot v-if="withActivator" name="activator" v-bind:on="on" v-bind:attrs="attrs"></slot>
    </template>
    <v-list>
      <v-list-item v-for="(menuVoice, index) in cxtMenuItems" :key="index" link>
        <v-list-item-title @click="menuVoice.handleClick(ctxItem, menuVoice, $event)">
          <v-icon v-if="menuVoice.icon">{{ menuVoice.icon }}</v-icon>
          {{ menuVoice.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import Translations from "@/mixins/translations";

@Component({
  name: "MediaContextMenu",
  props: {
    ctxItem: Object,
    withActivator: Boolean,
    contextMenu: Object,
  },
})
export default class MediaContextMenu extends mixins(Translations) {
  get cxtMenuAllItems () {
    return [
      {
        icon: "mdi-folder-open-outline",
        label: this.translations.labels.medialib_ctxMenuOpen,
        showWhen: () => this.isDir,
        handleClick: (item, voice) => {
          this.$emit("mediacontextmenu:openfolder", item, voice);
        }
      },
      {
        icon: "mdi-pencil",
        label: this.translations.labels.medialib_ctxMenuEdit,
        // showWhen: () => !this.isDir,
        showWhen: () => false, // TODO: finish implementation
        handleClick: (item, voice) => {
          this.$emit("mediacontextmenu:editfile", item, voice);
        }
      },
      {
        icon: "mdi-form-textbox",
        label: this.translations.labels.medialib_ctxMenuRename,
        showWhen: () => !this.isParentDir,
        handleClick: (item, voice) => {
          this.$emit("mediacontextmenu:rename", item, voice);
        }
      },
      {
        icon: "mdi-link-variant",
        label: this.translations.labels.medialib_ctxMenuGetPublicUrl,
        showWhen: () => !this.isDir,
        handleClick: (item, voice) => {
          this.$emit("mediacontextmenu:getpublicurl", item, voice);
        }
      },
      {
        icon: "mdi-download",
        label: this.translations.labels.medialib_ctxMenuDownload,
        showWhen: () => !this.isDir,
        handleClick: (item, voice) => {
          this.$emit("mediacontextmenu:download", item, voice);
        }
      },
      {
        icon: "mdi-trash-can-outline",
        label: this.translations.labels.medialib_ctxMenuDelete,
        showWhen: () => !this.isParentDir,
        handleClick: (item, voice) => {
          this.$emit("mediacontextmenu:delete", item, voice);
        }
      },
      {
        icon: "mdi-delete-restore",
        label: this.translations.labels.medialib_ctxMenuRestore,
        showWhen: () => {
          return !this.isDir && this.isDeleted;
        },
        handleClick: (item, voice) => {
          this.$emit("mediacontextmenu:restore-file", item, voice);
        }
      },
      {
        icon: "mdi-zip-box-outline",
        label: this.translations.labels.medialib_ctxMenuCompress,
        showWhen: () => {
          return !this.ctxItem?.isDeleted && (this.ctxItem?.canCompress ?? false);
        },
        handleClick: (item, voice) => {
          this.$emit("mediacontextmenu:compress-file", item, voice);
        }
      }
    ];
  }

  get isDir () {
    return !!this.ctxItem?.isDir;
  }

  get isParentDir () {
    return !!this.ctxItem?.isParentDir;
  }

  get isDeleted () {
    return !!this.ctxItem?.isDeleted;
  }

  get cxtMenuItems () {
    return this.cxtMenuAllItems.filter(i => i.showWhen());
  }
}
</script>