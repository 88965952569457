<template>
  <v-sheet class="mediagrid m-0">
    <v-container no-gutters fluid @contextmenu="(e) => { e.preventDefault(); }">
        <v-row v-if="items.length > 0">
          <v-col v-for="(item, i) in items" :key="i" :class="isDialog ? 'mediagrid__column-dialog-view' : 'mediagrid__column-component-view'">
              <div class="mediagrid__selectable">
                <MediaCard
                  @drag-start="onDragStart"
                  @drag-enter="onDragEnter(item)"
                  @drag-leave="onDragLeave(item)"
                  v-draggable="{ ...item }"
                  v-droppable
                  @drag-drop="(moveItem(item))($event)"
                  @card:select="toggle(item)"
                  @card:open="doubleClickItem"
                  @card:contextmenu="openContextMenu"
                  :active="isSelected(item)"
                  :item="item"
                  :drag-hovered="isDragHovered(item)"
                />
              </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col :class="isDialog ? 'mediagrid__column-dialog-view' : 'mediagrid__column-component-view'">
            {{ translations.labels.medialib_noResults }}
          </v-col>
        </v-row>
    </v-container>
    <MediaContextMenu
      :ctx-item="ctxItem"
      :context-menu="contextMenu"
      :with-activator="false"
      @mediacontextmenu:getpublicurl="getpublicurl"
      @mediacontextmenu:openfolder="openFolder"
      @mediacontextmenu:editfile="editFile"
      @mediacontextmenu:rename="rename"
      @mediacontextmenu:download="download"
      @mediacontextmenu:delete="deleteItem"
      @mediacontextmenu:restore-file="restoreFile"
      @mediacontextmenu:compress-file="compressFile"
    />
  </v-sheet>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import Translations from "@/mixins/translations";
import MediaCard from "@/components/medialib/items/grid/MediaCard";
import MediaContextMenu from "@/components/medialib/contextmenu/MediaContextMenu";
import { draggable, droppable } from "v-drag-drop";

@Component({
  name: "MediaGrid",
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    isDialog: Boolean,
    layout: String,
    items: Array,
    multiple: Boolean,
    propagateDblClickFile: {
      type: Boolean,
      default: false
    },
  },
  components: {
    MediaCard,
    MediaContextMenu,
  },
  directives: {
    draggable,
    droppable
  },
  watch: {
    internalSelected: function(newVal) {
      this.$emit(
        "mediagrid:update-selected",
        newVal
      );
    },
  },
})
export default class MediaGrid extends mixins(Translations) {
  internalSelected = [];
  ctxItem = null;
  dragHovered = null;

  CONTEXT_MENU_DELAY = 75;

  contextMenu = {
    show: false,
    x: null,
    y: null,
  };

  isSelected (item) {
    return (this?.internalSelected ?? []).findIndex(i => i.id === item.id) >= 0;
  }

  toggle (item) {
    // if selected
    if (this.isSelected(item)) {
      // if multiple, remove its occurrence in the selected array
      if (this.multiple) {
        const targetIndex = (this?.internalSelected ?? []).findIndex(i => i.id === item.id);
        if (targetIndex >= 0) {
          this.internalSelected.splice(targetIndex, 1);
        }
      // otherwise empty the selected array
      } else {
        this.internalSelected = [];
      }
    // if not selected
    } else {
      // if multiple, push the item in the selected array
      if (this.multiple) {
        this.internalSelected.push(item);
      // otherwise replace the only item in the array with this item
      } else {
        this.internalSelected.splice(0, 1, item);
      }
    }
  }

  getpublicurl (item, voice) {
    this.$emit("mediagrid:getpublicurl", item, voice);
  }
  openFolder (item, voice) {
    this.$emit("mediagrid:openfolder", item, voice);
  }
  editFile (item, voice) {
    this.$emit("mediagrid:editfile", item, voice);
  }
  rename (item, voice) {
    this.$emit("mediagrid:rename", item, voice);
  }
  download (item, voice) {
    this.$emit("mediagrid:download", item, voice);
  }
  deleteItem (item, voice) {
    this.$emit("mediagrid:delete", item, voice);
  }
  restoreFile (item, voice) {
    this.$emit("mediagrid:restore-file", item, voice);
  }
  compressFile (item, voice) {
    this.$emit("mediagrid:compress-file", item, voice);
  }

  doubleClickItem (item) {
    if (item.isDir) {
      this.$emit("mediagrid:openfolder", item);
    } else if (!this.propagateDblClickFile) {
      this.$emit("mediagrid:editfile", item);
    } else {
      this.$emit("mediagrid:dblclickfile", item);
    }
  }

  openContextMenu (item, e) {
    e.preventDefault();
    if (this.enableContextMenu) {
      return;
    }
    this.ctxItem = item;
    this.contextMenu.show = false;
    setTimeout(() => {
      this.contextMenu.x = e.clientX;
      this.contextMenu.y = e.clientY;
      this.$nextTick(() => {
        this.contextMenu.show = true;
      });
    }, this.CONTEXT_MENU_DELAY);
  }

  onDragStart(item, event) {
    // the parent dir is not draggable; we preventDefault the event if we are trying to drag that
    if (item.isDir && item.isParentDir) {
      event.preventDefault();
      return;
    }
    // system dir are not draggable too
    if (item.isSystem) {
      event.preventDefault();
      return;
    }
  }

  onDragEnter (item) {
    if (!item.isDir) {
      return;
    }
    this.dragHovered = item.id;
  }

  onDragLeave (item) {
    if (this.dragHovered === item.id) {
      this.dragHovered = null;
    }
  }

  isDragHovered (item) {
    return this.dragHovered === item.id;
  }

  moveItem (item) {
    return (data) => {
      if (!item.isDir) {
        return;
      }
      this.$emit("mediagrid:move", item, data);
    };
  }

  created() {
    this.internalSelected = this.selected;
  }
}
</script>

<style lang="scss" scoped>
.mediagrid {
  &__selectable {
    cursor: pointer;
  }
  &__column-component-view{
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;

    @media (min-width: 1400px) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    @media (min-width: 1650px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  &__column-dialog-view {
    flex: 0 0 50%;
    max-width: 50%;

    @media (min-width: 700px) {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    @media (min-width: 950px) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    @media (min-width: 1200px) {
      flex: 0 0 20%;
      max-width: 20%;
    }

    @media (min-width: 1500px) {
      flex: 0 0 16.6666666666%;
      max-width: 16.6666666666%;
    }
  }
}
</style>